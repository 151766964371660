import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "none",
    width: "100%",
    height: 600,
    display: 'grid',
  },
  text: {
    placeSelf: 'center',
    textAlign: 'center',
    color: '#fbff00',
    textShadow: '0px 2px 5px rgba(0,0,0,0.5)',
    fontWeight: 900
  },
}));

export default function Banner() {
  const classes = useStyles();

  // data from graphql
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1243) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage name="home" fluid={data.placeholderImage.childImageSharp.fluid} className={classes.root}>
    </BackgroundImage>
  );
}
