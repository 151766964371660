import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 25
  },
  title: {
    textAlign: "center",
    marginTop: 15,
    marginBottom: 15,
    padding: 10
  },
  item: {
    padding: 5
  }
}));

const text = {
  vi: {
    'project': "Công trình đã thực hiện",
  },
  en: {
    'project': "Projects",
  }
}

export default function HomeGallery({ language }) {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image_1: file(relativePath: { eq: "gallery/image_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_2: file(relativePath: { eq: "gallery/image_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_3: file(relativePath: { eq: "gallery/image_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_4: file(relativePath: { eq: "gallery/image_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_5: file(relativePath: { eq: "gallery/image_5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_6: file(relativePath: { eq: "gallery/image_6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_7: file(relativePath: { eq: "gallery/image_7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_8: file(relativePath: { eq: "gallery/image_8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_9: file(relativePath: { eq: "completed-project/image_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_10: file(relativePath: { eq: "completed-project/image_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_11: file(relativePath: { eq: "completed-project/image_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_12: file(relativePath: { eq: "completed-project/image_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_13: file(relativePath: { eq: "completed-project/image_5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_14: file(relativePath: { eq: "completed-project/image_6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 235) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div name="project" className={classes.root}>
      <Typography variant='h4' className={classes.title}>
        {text[language].project}
      </Typography>
      <Grid container spacing={0}>
        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_1.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_2.childImageSharp.fluid}
          />
        </Grid>
        
        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_3.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_4.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_5.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_6.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_7.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={3} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_8.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={4} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_9.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={4} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_10.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={4} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_11.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={4} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_12.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={4} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_13.childImageSharp.fluid}
          />
        </Grid>

        <Grid item md={4} xs={12} className={classes.item}>
          <Img
            style={{ width: "100%" }}
            fluid={data.image_14.childImageSharp.fluid}
          />
        </Grid>
      </Grid>
    </div>
  );
}

HomeGallery.defaultProps = {
  language: 'vi',
}

HomeGallery.propTypes = {
  language: PropTypes.string,
}